import React from 'react'

function GradientSpinner({className}) {
	return (
		<svg className={`loader ${className}`} viewBox="0 0 100 100" xmlns="http://www.w3.org/2000/svg">
			<circle cx="50" cy="50" r="46" fill="none" stroke="url(#gradient)" stroke-width="8" />
			<defs>
				<linearGradient id="gradient" gradientUnits="userSpaceOnUse" x1="100" y1="0" x2="0" y2="100">
					<stop offset="0%" stop-color="#f472b6" />
					<stop offset="50%" stop-color="#804ae4" />
					<stop offset="100%" stop-color="#02f6d1" />
				</linearGradient>
			</defs>
		</svg>
	)
}

export default GradientSpinner