import React from "react";
import { useSelector, useDispatch } from 'react-redux';
import { toggleModal } from '../redux/reservedDomainsSlice';
import ReservedDomainModal from '../components/modal/ReservedDomainModal';
import Sidebar from "../components/Sidebar";
import { BsArrow90DegRight, BsCollection, BsCurrencyDollar } from "react-icons/bs";
import { RiNftLine } from "react-icons/ri";
import {
  MdDomain,
  MdOutlinePriceChange,
  MdSpaceDashboard,
} from "react-icons/md";
import { FaTools } from "react-icons/fa";
import { SlTarget } from "react-icons/sl";
import {
  IoCreateOutline,
  IoFileTrayStackedOutline,
} from "react-icons/io5";
import { TiFlowMerge } from "react-icons/ti";
import { PiUsers } from "react-icons/pi";


const DashboardLayout = ({ children }) => {
  const dispatch = useDispatch();
  const isModalOpen = useSelector(state => state.reservedDomains.isModalOpen);

  const handleModalClose = () => {
    dispatch(toggleModal(false));
  };

  const routes = [
    {
      icon: MdSpaceDashboard,
      name: "Dashboard",
      path: "/dashboard",
    },
    {
      icon: MdDomain,
      name: "My Domains",
      path: "/dashboard/my-domains",
      // children: [
      //   {
      //     name: "Top-Level Domains",
      //     icon: IoCreateOutline,
      //     path: "/dashboard/domain/tlds",
      //   },
      //   {
      //     name: "Sub-Level Domains",
      //     icon: IoCreateOutline,
      //     path: "/dashboard/domain/slds",
      //   },
      //   {
      //     name: "Reserved Domains",
      //     icon: IoCreateOutline,
      //     path: "/dashboard/domain/reserved",
      //   },
      // ],
    },
    {
      icon: TiFlowMerge,
      name: "Flows",
      path: "/dashboard/flows",

      // children: [
      //   {
      //     name: "Onboard Page",
      //     icon: IoCreateOutline,
      //     path: "/dashboard/domain/tlds",
      //   },
      //   {
      //     name: "Signup Page",
      //     icon: IoCreateOutline,
      //     path: "/dashboard/domain/dddslds",
      //   },
      //   {
      //     name: "Signin Page",
      //     icon: IoCreateOutline,
      //     path: "/dashboard/domain/dsdslds",
      //   },
      //   {
      //     name: "Branded Wallet",
      //     image: '/domainz-dark.svg',
      //     path: "/dashboard/signup/branded-wallet",
      //   },
      // ],
    },
    // {
    //   icon: RiPagesLine,
    //   name: "Manage Sign Up",
    //   children: [
    //     {
    //       name: "Sign Up Form",
    //       icon: IoCreateOutline,
    //       path: "/dashboard/signup/form",
    //     },
    //     {
    //       name: "Thank You Forms",
    //       icon: IoCheckmarkCircleOutline,
    //       path: "/dashboard/signup/thank-you",
    //     },
    //     {
    //       name: "Sign In",
    //       icon: IoLogInOutline,
    //       path: "/dashboard/signup/signin",
    //     },
    //     {
    //       name: "Redirect Flow",
    //       icon: IoArrowForwardCircleOutline,
    //       path: "/dashboard/signup/redirect",
    //     },
    //   ],
    // },

    // {
    //   icon: RiNftLine,
    //   name: "NFT Launchpad",
    //   // path: "/dashboard/nft-launchpad",
    //   // comingSoon: true,
    //   children: [
    //     {
    //       name: "Create NFT",
    //       icon: IoCreateOutline,
    //       path: "/dashboard/nft-launchpad",
    //     },
    //     {
    //       name: "AirDrop",
    //       icon: SlTarget,
    //       path: "#",
    //       // comingSoon: true,
    //     },
    //     {
    //       name: "Create Collection",
    //       icon: IoFileTrayStackedOutline,
    //       path: "#",
    //       // comingSoon: true,
    //     },
    //     {
    //       name: "Manage Collection",
    //       icon: BsCollection,
    //       path: "#",
    //       // comingSoon: true,
    //     },
    //   ],
    // },
    {
      icon: PiUsers,
      name: "Audience Management",
      path: "/dashboard/audience-management",
      // comingSoon: true,
    },
    {
      icon: FaTools,
      name: "Domain Tools",
      path: "/dashboard/domains-tools",
      // children: [
      //   {
      //     name: "Domain Transfer",
      //     path: "/dashboard/transfer",
      //     icon: BsArrow90DegRight,
      //   },
  
      //   {
      //     name: "Reverse Resolution",
      //     path: "/dashboard/resolution",
      //     icon: BiTransfer,
      //     comingSoon: true,
      //   },
      //   // {
      //   //   name: "TLD Resell",
      //   //   path: "/dashboard/tld-reseller",
      //   //   icon: BiDollarCircle,
      //   //   comingSoon: true,
      //   // },
      //   // {
      //   //   name: "Account Settings",
      //   //   path: "/account-settings",
      //   //   icon: FaRegUser,
      //   //   comingSoon: true,
      //   // },
      // ],
    },
    // {
    //   icon: BiSolidUserAccount,
    //   name: "AI Agents",
    //   path: "/dashboard/financials",
    //   comingSoon: true,
    // },
    {
      icon: BsCurrencyDollar,
      name: "Payouts",
      path: "/dashboard/financials",
    },
  ];


  return (
    <div className=" relative flex flex-col md:flex-row gap-2 mt-32 ">
      <Sidebar routes={routes} />
      <div className="flex-grow h-full dark:bg-jacarta-900">
        <main className="">{children}</main>
      </div>
      <ReservedDomainModal 
        isOpen={isModalOpen} 
        setIsOpen={handleModalClose}
      />
    </div>
  );
};

export default DashboardLayout;
