import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  domains: [],
  isModalOpen: false
};

const reservedDomainsSlice = createSlice({
  name: 'reservedDomains',
  initialState,
  reducers: {
    setDomains: (state, action) => {
      state.domains = action.payload;
    },
    toggleModal: (state, action) => {
      state.isModalOpen = action.payload;
    }
  }
});

export const { setDomains, toggleModal } = reservedDomainsSlice.actions;
export default reservedDomainsSlice.reducer; 