export default function parseDomain(input) {
  // Trim whitespace and convert input to lowercase
  const trimmedInput = input.trim().toLowerCase();

  // Uncomment the below if needed
  // Check if input is empty or does not contain any alphabetic characters
  // if (!trimmedInput || !/[a-z]/i.test(trimmedInput)) {
  //   throw new Error("Invalid input. Please enter a valid domain.");
  // }

  // Split the input on the dot
  const parts = trimmedInput.split(".");

  // Initialize variables for TLD and SLD
  let tld, sld;

  if (parts.length === 1) {
    // Handle inputs like "netzylo" where no dot is present
    tld = parts[0];
    sld = null;
  } else {
    // Extract TLD and SLD from parts
    sld = parts[0];
    tld = parts.slice(1).join("."); // Join remaining parts in case of multiple dots
  }

  // Final check to ensure TLD is not empty
  if (!tld) {
    throw new Error("Missing top-level domain. Please include a TLD after the dot.");
  }

  // Ensure TLD starts with a dot
  if (!tld.startsWith(".")) {
    tld = "." + tld;
  }

  // Determine the type of domain based on the SLD and TLD
  const type = sld ? "domain" : "tld"; // Changed from 'sld' to 'domain' to clarify meaning

  return {
    tld,
    sld: sld || null, // If SLD is empty, return null for SLD
    type,
  };
}
