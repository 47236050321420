import { configureStore } from "@reduxjs/toolkit";
// import counterReducer from '../features/counter/counterSlice';
import counterReducer from "./counterSlice";
import cartReducer from "./cartSlice";
import authReducer from "./authSlice";
import discoverDomain from "./discoverSlice";
import flowsReducer from './slices/flowsSlice';
import reservedDomainsReducer from './reservedDomainsSlice';
export const store = configureStore({
  reducer: {
    counter: counterReducer,
    cart: cartReducer,
    auth: authReducer,
    discoverDomain: discoverDomain,
    flows: flowsReducer,
    reservedDomains: reservedDomainsReducer,
  },
});
