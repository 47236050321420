import Link from "next/link";
import Image from "next/image";
import { useTheme } from "next-themes";
import { Fade } from "react-reveal";
import {
  FaDiscord,
  FaFacebookF,
  FaLinkedinIn,
  FaInstagram,
} from "react-icons/fa";
import { toast } from "react-hot-toast";
import { useRef } from "react";
import { useFormspark } from "@formspark/use-formspark";
import Loading from "./Loading";
import { FaXTwitter } from "react-icons/fa6";
import Logo from "../public/Logo-dark.png";
import WhiteLogo from "../public/Logo-white.png";

import DarkMode from "./mode/DarkMode";


const footer = () => {
  const { theme } = useTheme();

  const formRef = useRef();
  const FORMSPARK_FORM_ID = "HPEVBpuX";
  const [submit, submitting] = useFormspark({
    formId: FORMSPARK_FORM_ID,
  });

  const onSubmit = async (e) => {
    e.preventDefault();
    const formData = {};
    const formElements = formRef.current.elements;

    for (let i = 0; i < formElements.length; i++) {
      const element = formElements[i];
      if (element.name) {
        formData[element.name] = element.value;
      }
    }
    await submit({ ...formData });
    toast.success("Thanks for subscribing!");
    formRef.current.reset();
  };

  return (
    <>
      {/* <!-- Footer --> */}
      <div className="border border-gray-900 border-t border-b-0 border-x-0">
        <footer className="container px-4 lg:px-0 dark:bg-jacarta-900 page-footer   bg-white relative overflow-hidden z-[10]">
          <Fade ssrReveal delay={350} cascade>
            <div className="absolute -bottom-[30%] -left-[15%] hidden dark:block ">
              <div className="relative z-10 bg-white dark:bg-jacarta-900">
                <div className="footer-background z-[inherit] hidden dark:block"></div>
                {/* <div className="footer-background-2 z-[inherit] block dark:hidden"></div> */}

                <div
                  className="h-[500px] animate-fade "
                  style={{
                    mixBlendMode: theme === "dark" ? "lighten" : "difference",
                  }}
                >
                  <Image
                    height={200}
                    width={900}
                    className="object-contain"
                    src="/images/background-footer.webp"
                    alt=""
                  />
                </div>
              </div>
            </div>
            <div className=" !relative z-20 ">
              <div className="grid grid-cols-1  gap-y-14 pt-12 lg:pt-24 pb-8 md:grid-cols-2">
                <div className=" ">
                  <div className="relative">
                    {/* <!-- Logo --> */}
                    <Link href="#" className="mb-6 inline-block">
                      <Image
                        src={WhiteLogo}
                        height={28}
                        width={110}
                        alt="Netzylo"
                        className="dark:hidden"
                      />
                    </Link>
                    <Link href="#" className=" mb-6 inline-block">
                      <Image
                        src={Logo}
                        height={28}
                        width={110}
                        alt="Netzylo"
                        className="hidden dark:block"
                      />
                    </Link>
                   
                    <p className="dark:text-white mb-6 w-3/4">
                      Join today for exclusive discounts on TLDs, early access
                      to new platform features, valuable market insights, and
                      more.
                    </p>{" "}
                    <form
                      ref={formRef}
                      onSubmit={onSubmit}
                      className="  z-20  mr-auto backdrop-blur w-full max-w-[380px]"
                    >
                      {/* <p className="dark:text-white text-center text-base ">Join our newsletter to stay up to date on futures and releases.</p> */}
                      <div>
                        <input
                          type="email"
                          placeholder="Enter your Email..."
                          required={true}
                          className="rounded-lg placeholder:dark:text-white bg-jacarta-500 dark:bg-jacarta-600 w-full py-3 px-6 my-6"
                        />
                      </div>
                      <button
                        type="submit"
                        className={`text-center justify-center rounded-md text-[18px] font-medium  py-[10px] px-[32px] md:py-[12px] md:px-[38px] cursor-pointer button z-[2] transition-all duration-500 text-white hover:-translate-y-1 w-full block md:inline  ${
                          !submitting
                            ? " bg-gradient-to-tr from-hotPink via-purplish to-accent"
                            : " disabled:text-transparent disabled:bg-jacarta-800"
                        }  relative `}
                        disabled={submitting}
                      >
                        {submitting ? <Loading size={5} /> : "Sign Up Now"}
                      </button>
                    </form>
                    <div className="flex items-center space-x-5 mt-5">
                      <Link target="_blank" href="https://twitter.com/NetZylo/">
                        <FaXTwitter className="w-[39px] h-[39px] p-2 rounded-lg text-white bg-jacarta-800 hover:bg-white hover:text-gray-300 transition-all  duration-500  hover:-translate-y-1 border border-gray-200" />
                      </Link>
                      <Link
                        target="_blank"
                        href="https://www.linkedin.com/company/netzylo/"
                      >
                        <FaLinkedinIn className="w-[39px] h-[39px] p-2 rounded-lg text-white bg-jacarta-800 hover:bg-white hover:text-gray-300 transition-all  duration-500  hover:-translate-y-1 border border-gray-200" />
                      </Link>
                      <Link
                        target="_blank"
                        href="https://instagram.com/netzylo/"
                      >
                        <FaInstagram className="w-[39px] h-[39px] p-2 rounded-lg text-white bg-jacarta-800 hover:bg-white hover:text-gray-300 transition-all  duration-500  hover:-translate-y-1 border border-gray-200" />
                      </Link>
                      <Link
                        target="_blank"
                        href="https://www.facebook.com/NetZyloInc/"
                      >
                        <FaFacebookF className="w-[39px] h-[39px] p-2 rounded-lg text-white bg-jacarta-800 hover:bg-white hover:text-gray-300 transition-all duration-500  hover:-translate-y-1 border border-gray-200" />
                      </Link>
                    </div>
                    {/* <!-- Socials --> */}
                  </div>
                </div>
                <div className="flex items-center">
                  <div className="md:border md:border-l-jacarta-600 md:border-r-0 md:border-y-0 md:px-6 lg:px-10 flex gap-2 lg:gap-8 justify-between w-full">
                    <div>
                      <p className="text-lg lg:text-xl dark:text-white pb-6 md:pb-10 font-bold">
                        Menu
                      </p>
                      <div className="pb-6">
                        <Link
                          href="/domains"
                          className="hover:text-accent text-sm xl:text-lg"
                        >
                          Domains
                        </Link>
                      </div>
                      <div className="pb-6">
                        <Link
                          href="/community"
                          className="hover:text-accent text-sm xl:text-lg"
                        >
                          Community
                        </Link>
                      </div>

       
                      <div className="pb-6">
                        <Link
                          href="/pricing"
                          className="hover:text-accent text-sm xl:text-lg"
                        >
                          Pricing
                        </Link>
                      </div>
                    </div>

                    <div>
                      <p className="text-lg lg:text-xl dark:text-white pb-6 md:pb-10 font-bold">
                        Crypto
                      </p>
                      <div className="pb-6">
                        <Link
                          href="/domainz"
                          className="hover:text-accent text-sm xl:text-lg"
                        >
                          $Domainz
                        </Link>
                      </div>
                      <div className="pb-6">
                        <Link
                          href="/signin"
                          className="hover:text-accent text-sm xl:text-lg"
                        >
                          Z Wallet
                        </Link>
                      </div>
                      <div className="pb-6">
                        <Link
                          href="/wallet"
                          className="hover:text-accent text-sm xl:text-lg"
                        >
                          Get Tangem
                        </Link>
                      </div>
                      {/* <div className="pb-6">
                        <Link
                          href="/learn"
                          className="hover:text-accent text-sm xl:text-lg"
                        >
                          Learn
                        </Link>
                      </div> */}
                    </div>

                    <div>
                      <p className="text-lg lg:text-xl dark:text-white pb-6 md:pb-10 font-bold">
                        About
                      </p>
                      <div className="pb-6">
                        <Link
                          href="/docs/api"
                          className="hover:text-accent text-sm xl:text-lg"
                        >
                          API
                        </Link>
                      </div>
                      <div className="pb-6">
                        <Link
                          href="/"
                          className="hover:text-accent text-sm xl:text-lg"
                        >
                          Resources
                        </Link>
                      </div>
                      <div className="pb-6">
                        <Link
                          href="/news"
                          className="hover:text-accent text-sm xl:text-lg"
                        >
                          News
                        </Link>
                      </div>
                      <div className="pb-6">
                        <Link
                          href="/blogs"
                          className="hover:text-accent text-sm xl:text-lg"
                        >
                          Blog
                        </Link>
                      </div>
                      <div className="pb-6">
                        <Link
                          href="/contact"
                          className="hover:text-accent text-sm xl:text-lg"
                        >
                          Contact
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Fade>
        </footer>

        <div className="container px-[20px] lg:px-0 !py-0 bg-white dark:bg-jacarta-900 relative z-[999]">
          <Fade ssrReveal delay={350} cascade>
            <div className="grid grid-cols-1 lg:grid-cols-3 items-center py-8 gap-3">
              {/* Left section */}
              <span className="dark:text-white text-sm">
                <span>© NetZylo Inc. All rights reserved | Powered by AI</span>
              </span>
              
              {/* Center section - DarkMode */}
              <div className="flex justify-center">
                <DarkMode />
              </div>

              {/* Right section */}
              <ul className="dark:text-white flex flex-row justify-end gap-8 md:gap-4 text-sm">
                <li>
                  <Link
                    href="/privacy"
                    className="hover:text-accent dark:hover:text-white"
                  >
                    Privacy policy
                  </Link>
                </li>
                <li>
                  <Link
                    href="/terms-and-conditions"
                    className="hover:text-accent dark:hover:text-white"
                  >
                    Terms and conditions
                  </Link>
                </li>
                <li>
                  <Link
                    href="/giveaway-terms"
                    className="hover:text-accent dark:hover:text-white"
                  >
                    Giveaway Terms
                  </Link>
                </li>
              </ul>
            </div>
          </Fade>
        </div>
      </div>
    </>
  );
};

export default footer;
