import axios from "axios";
// import { ethers, Wallet, JsonRpcProvider } from "ethers";

// import getSignatureForMinting from "./getSignatureForMinting";
// import getMintingDetails from "./getMintingDetails";
// import web3Config from "../web3Config";
// import getDecryptedRPC from "./getDecryptedRPC";

async function mintNftFromAdminWallet(targetWallets, domainNames) {
  try {
    // Call the API endpoint using axios
    const response = await axios.post("/api/mintnft", {
      targetWallets,
      domainNames,
    });

    const data = response.data;

    if (!data.success) {
      throw new Error(data.message || "Failed to mint NFT");
    }

    console.log("success");
    return true;
  } catch (error) {
    console.error("Minting error:", error.response?.data || error.message);
    return false;
  }
}

export default mintNftFromAdminWallet;
