import { useState, useEffect } from "react";
import { Dialog } from "@headlessui/react";
import { FaXmark } from "react-icons/fa6";
import { FiSearch, FiCopy } from "react-icons/fi";
import { MdOutlineDomain } from "react-icons/md";
import { FaCheckCircle, FaTimesCircle } from "react-icons/fa";
import GradientSpinner from "../gradientSpinner";
import { BsLightningChargeFill } from "react-icons/bs";
import { HiUsers } from "react-icons/hi";
import { FaPercent } from "react-icons/fa";
import { IoTimeOutline } from "react-icons/io5";
import { useActiveAccount } from "thirdweb/react";
import parseDomain from "../../utils/parseDomain";

import checkDomainAvailability from "../../utils/checkDomainAvailability";
import mintNftFromAdminWallet from "../../utils/mintNftFromAdminWallet";
import { useQueryClient } from "react-query";
import { useDispatch, useSelector } from 'react-redux';
import { v4 as uuidv4 } from 'uuid';
import { addToCart } from '../../redux/cartSlice'; // adjust path as needed
import calculateDomainPrice from "../../utils/calcualteDomainPrice";
import { useRouter } from "next/router";

export default function ReservedDomainModal({ isOpen, setIsOpen }) {
  const [currentStep, setCurrentStep] = useState(0);
  const [searchTerm, setSearchTerm] = useState("");
  const [isMinting, setIsMinting] = useState(false);
  const [isSearching, setIsSearching] = useState(false);
  const [isCompleted, setIsCompleted] = useState(false);
  const [isAvailable, setIsAvailable] = useState(false);
  const [isPremium, setIsPremium] = useState(false);

  const router = useRouter()
const [domainPrice, setDomainPrice] = useState({
  price: 0,
  discountedPrice: 0,
  discountPercentage: 0
});
  const adminWalletAddress = "0x5D2D18E5d2D29466Ba0bc635Eb7565c7aC2181a6";
  const queryClient = useQueryClient();
  const user = useActiveAccount();
  const emailWalletAddress = user?.address;
  const dispatch = useDispatch();
  const {cartItems} = useSelector((state) => state.cart);
  const [isInCart, setIsInCart] = useState(false);

  const navigate = () => {
    router.push("/checkout");
  }

  // Reset states when modal closes
  useEffect(() => {
    if (!isOpen) {
      setIsMinting(false);
      setIsCompleted(false);
    }
  }, [isOpen]);

  // Start minting process when reaching step 2
  useEffect(() => {
    let mounted = true;

    const handleMinting = async () => {
      if (currentStep === 2) {
        setIsMinting(true);
        let domainToMint = searchTerm + ".DOMAINZ";
        domainToMint = domainToMint.toLowerCase();

        const parsedDomain = parseDomain(domainToMint);
        console.log({ parsedDomain });

        if (!emailWalletAddress) {
          alert("wallet not found");
          if (mounted) {
            setIsMinting(false);
          }
          return;
        }
        let domainNames = [domainToMint, "." + parsedDomain.sld];
        let targetWallets = [emailWalletAddress, adminWalletAddress];

        try {
          console.log("Calling mintNftFromAdminWallet");
          let res = await mintNftFromAdminWallet(targetWallets, domainNames);
          console.log("After Calling mintNftFromAdminWallet");

          if (mounted) {
            if (res) {
              setIsCompleted(true);
              setCurrentStep(3);
        const tldPrice  = calculateDomainPrice("", searchTerm);

        setDomainPrice({
          price: tldPrice.total,
          discountedPrice: tldPrice.total * 0.5,
          discountPercentage: 50,
        });
              queryClient.invalidateQueries('user-profile');
            } else {
              setIsCompleted(false);
              alert("minting failed");
            }
            setIsMinting(false);
          }
        } catch (error) {
          console.error("Minting error:", error);
          if (mounted) {
            setIsCompleted(false);
            setIsMinting(false);
            alert("minting failed");
          }
        }
      }
    };

    handleMinting();

    return () => {
      mounted = false;
    };
  }, [currentStep, emailWalletAddress, searchTerm]);

  useEffect(() => {
    if (searchTerm && cartItems) {
      const domainInCart = cartItems.some(
        (item) => item.tld === `.${searchTerm}` && item.sld === ""
      );
      setIsInCart(domainInCart);
    }
  }, [cartItems, searchTerm]);

  const handleSearch = async (e) => {
    e.preventDefault();
    setIsSearching(true);
    if (searchTerm.trim()) {
      // Simulate domain check
      let domainToSearch = searchTerm + ".domainz";
  
      const parsedDomain = parseDomain(domainToSearch);


      try {
        // Execute both checks in parallel
        const [isSldAvailable, isTldAvailable] = await Promise.all([
          checkDomainAvailability(domainToSearch),
          checkDomainAvailability("." + parsedDomain.sld),
        ]);

        if (isSldAvailable && isTldAvailable) {
          setIsAvailable(true);
          setIsPremium(false);
          setCurrentStep(1);
        } else {
          setIsAvailable(false);
          setIsPremium(false);
          setCurrentStep(1);
          console.log("Domain is not available");
        }
      } catch (error) {
        console.error("Error checking domain availability:", error);
      }
      setIsSearching(false);
    }
  };

  const handleAddToCart = () => {
    if (isInCart) return;

    const cartItem = {
      available: true,
      fullname: `.${searchTerm}`,
      id: uuidv4(),
      origin: "direct",
      ownedByUser: false,
      price: domainPrice?.discountedPrice, 
      sld: "",
      tld: `.${searchTerm}`,
      tldOwnerId: null,
      total: domainPrice?.discountedPrice, 
      type: "TLD"
    };

    dispatch(addToCart(cartItem));
    // navigate("/dashboard/checkout");
  };

  const renderStepContent = () => {
    if (currentStep === 0) {
      return (
        <>
          {/* Stars icon */}
          <div className="flex justify-center mb-6">
            <span className="text-4xl">✨</span>
          </div>

          {/* Title */}
          <Dialog.Title className="text-2xl md:text-3xl font-bold text-center text-jacarta-700 dark:text-white mb-6">
            Congratulations !! You opened your wallet
          </Dialog.Title>

          {/* Description */}
          <p className=" text-sm md:text-lg  dark:text-jacarta-300 text-center mb-8">
            Now pick your freeSLD from our premium .DOMAINZ Top - Level Domain that can be used to as your digital identity. Search your name,
            business name, interests, gamertags etc.
          </p>

          {/* Search form */}
          <form onSubmit={handleSearch} className="w-full max-w-2xl mx-auto">
            <div className="relative gradient-box dark:bg-jacarta-900 bg-white border-2 rounded-md flex items-stretch z-[1]">
              <div className="flex items-center w-full m-0">
                <input
                  type="text"
                  value={searchTerm}
                  onChange={(e) => setSearchTerm(e.target.value)}
                  placeholder="Type the domain you want"
                  className="!outline-none p-4 text-jacarta-800 dark:text-white text-lg dark border-0 rounded-l-md dark:bg-jacarta-700 bg-jacarta-100 focus:!border-transparent focus:!outline-transparent border-transparent focus:ring-0 dark:placeholder-white/70 placeholder-gray-600 w-full placeholder:text-sm md:placeholder:text-lg"
                  required
                />
                <button
                  type="submit"
                  className={`px-8 font-bold py-4 rounded-r-[5px] transition-all duration-200 ${
                    isSearching ? "bg-gray-400 cursor-not-allowed" : "bg-accent text-white hover:bg-accent/80"
                  }`}
                  disabled={isSearching}
                >
                  {isSearching ? "Searching..." : "Search"}
                </button>
              </div>
            </div>
          </form>
        </>
      );
    }

    if (currentStep === 1) {
      return (
        <div className="flex flex-col items-center">
          {/* Domain Status Banner */}
          <div
            className={`w-full rounded-md p-6 mb-2 text-center shadow-lg relative ${
              isAvailable ? "bg-gradient-to-r from-green-400 to-blue-500 dark:from-green-900 dark:to-blue-900" : "bg-red-100 dark:bg-red-900"
            }`}
          >
            {isPremium && (
              <div className="absolute -top-4 left-0 transform">
                <div className="relative">
                  {/* Glow effect */}
                  <div className="absolute inset-0 bg-yellow-400 blur opacity-50 rounded-md"></div>

                  {/* Badge */}
                  <div className="relative flex items-center gap-2 px-4 py-2 bg-gradient-to-r from-yellow-300 to-yellow-500 rounded-full shadow-lg border border-yellow-200">
                    <svg className="w-4 h-4 text-yellow-900" fill="currentColor" viewBox="0 0 20 20">
                      <path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z" />
                    </svg>
                    <span className="font-bold text-yellow-900">Premium</span>
                  </div>
                </div>
              </div>
            )}

            <div className="flex items-center justify-center gap-3 mb-4">
              <MdOutlineDomain className="text-4xl text-white" />
              <h3 className="text-3xl font-bold text-white">
                {searchTerm}
                <span className="text-accent">.domainz</span>
              </h3>
            </div>
            <div className="flex items-center justify-center gap-2 text-white">
              {isAvailable ? (
                <>
                  <FaCheckCircle className="text-2xl" />
                  <span className="text-lg font-medium">Available for registration</span>
                </>
              ) : (
                <>
                  <FaTimesCircle className="text-2xl" />
                  <span className="text-lg font-medium">Not available</span>
                </>
              )}
            </div>
          </div>

          {/* Features Grid */}
          {isAvailable && (
            <div className="grid grid-cols-1 md:grid-cols-3 gap-4 w-full mb-8">
              <div className="bg-jacarta-50 dark:bg-jacarta-600 rounded-md p-4 flex flex-col items-center text-center transition-transform hover:scale-105">
                <div className="bg-accent/10 p-3 rounded-full mb-3">
                  <FaCheckCircle className="text-accent text-xl" />
                </div>
                <h4 className="font-semibold text-jacarta-700 dark:text-white mb-2">Premium Domain</h4>
                <p className="text-sm  dark:text-jacarta-300">Secure your digital identity</p>
              </div>

              <div className="bg-jacarta-50 dark:bg-jacarta-600 rounded-md p-4 flex flex-col items-center text-center transition-transform hover:scale-105">
                <div className="bg-accent/10 p-3 rounded-full mb-3">
                  <FaCheckCircle className="text-accent text-xl" />
                </div>
                <h4 className="font-semibold text-jacarta-700 dark:text-white mb-2">100% Ownership</h4>
                <p className="text-sm  dark:text-jacarta-300">Yours for life on blockchain</p>
              </div>

              <div className="bg-jacarta-50 dark:bg-jacarta-600 rounded-md p-4 flex flex-col items-center text-center transition-transform hover:scale-105">
                <div className="bg-accent/10 p-3 rounded-full mb-3">
                  <FaCheckCircle className="text-accent text-xl" />
                </div>
                <h4 className="font-semibold text-jacarta-700 dark:text-white mb-2">Web3 Ready</h4>
                <p className="text-sm  dark:text-jacarta-300">Built for the future web</p>
              </div>
            </div>
          )}

          {/* Action Buttons */}
          <div className="flex flex-col sm:flex-row gap-4 w-full max-w-md mx-auto">
            {isAvailable ? (
              <button
                onClick={() => {
                  setCurrentStep(2);
                }}
                className="flex-1 px-6 py-3 rounded-md bg-gradient-to-tr from-hotPink via-purplish to-accent text-white hover:opacity-90 transition-all duration-200 font-bold flex items-center justify-center gap-2"
              >
                Register Now
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={2} stroke="currentColor" className="w-5 h-5">
                  <path strokeLinecap="round" strokeLinejoin="round" d="M13.5 4.5 21 12m0 0-7.5 7.5M21 12H3" />
                </svg>
              </button>
            ) : (
              <button
                onClick={() => setCurrentStep(0)}
                className="flex-1 px-6 py-3 rounded-md bg-red text-white hover:bg-red/90 transition-all duration-200 font-bold"
              >
                Try Another
              </button>
            )}
            <button
              onClick={() => {
                setSearchTerm("");
                setCurrentStep(0);
              }}
              className="flex-1 px-6 py-3 rounded-md border-2 border-accent text-accent hover:bg-accent/10 transition-all duration-200 font-bold"
            >
              Search Again
            </button>
          </div>
        </div>
      );
    }

    if (currentStep === 2) {
      return (
        <div className="flex flex-col items-center">
          {/* Minting Status Card */}
          <div className="w-full rounded-2xl p-8 text-center relative overflow-hidden bg-gradient-to-r from-purple-500 to-blue-500 dark:from-purple-900 dark:to-blue-900">
            {/* Background Pattern */}
            <div className="absolute inset-0 opacity-10">
              <div className="absolute inset-0 bg-repeat bg-[url('/pattern.svg')]"></div>
            </div>

            {/* Content */}
            <div className="relative z-10">
              {/* Domain Info */}
              <div className="inline-flex items-center gap-2 px-4 py-2 bg-white/10 rounded-full mb-6">
                <MdOutlineDomain className="text-xl text-white" />
                <span className="text-lg font-bold text-white">Domain Minting</span>
              </div>

              {/* Domain Name */}
              <h3 className="text-2xl font-bold text-white mb-4">
                {searchTerm}
                <span className="text-accent">.domainz</span>
              </h3>

              {/* Wallet Address */}
              <div className="bg-black/20 rounded-md p-3 mb-6 mx-auto max-w-md">
                <p className="text-white/70 text-sm mb-1">Minting to wallet</p>
                <div className="flex items-center justify-center gap-2 text-white break-all">
                  <span className="font-mono text-sm">{user.address}</span>
                  <button className="text-accent hover:text-accent/80">
                    <FiCopy className="w-4 h-4" />
                  </button>
                </div>
              </div>

              {/* Minting Status with GradientSpinner */}
              <div className="flex flex-col items-center justify-center gap-4">
                {isMinting ? (
                  <>
                    <GradientSpinner className="w-12 h-12" />
                    <span className="text-white font-medium">Processing transaction...</span>
                  </>
                ) : isCompleted ? (
                  <>
                    <FaCheckCircle className="text-green-400 text-4xl" />
                    <span className="text-white font-medium">Minting completed!</span>
                  </>
                ) : null}
              </div>
            </div>
          </div>

          {/* Action Button */}
          <div className="mt-6 w-full max-w-md">
            <button
              onClick={() => {
                /* Add your logic */
              }}
              disabled={isMinting}
              className={`w-full px-6 py-3 rounded-md font-bold flex items-center justify-center gap-2 transition-all duration-200
                ${isMinting ? "bg-jacarta-600 text-white cursor-not-allowed" : "bg-accent text-white hover:bg-accent/90"}`}
            >
              {isMinting ? (
                "Minting in progress..."
              ) : (
                <>
                  Continue to Dashboard
                  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={2} stroke="currentColor" className="w-5 h-5">
                    <path strokeLinecap="round" strokeLinejoin="round" d="M13.5 4.5 21 12m0 0-7.5 7.5M21 12H3" />
                  </svg>
                </>
              )}
            </button>
          </div>
        </div>
      );
    }

    if (currentStep === 3) {
      return (
        <div className="flex flex-col items-center bg-white dark:bg-jacarta-700 p-6 ">
          {/* Existing Header Section */}
          <div className="flex items-center justify-between w-full mb-4">
            <div className="flex items-center gap-2">
              <div className="bg-accent/10 p-2 rounded-full">
                <BsLightningChargeFill className="text-accent text-xl" />
              </div>
              <h3 className="text-xl font-bold ">
                TLD <span className="text-accent">.{searchTerm}</span>
              </h3>
            </div>
            <div className="flex items-end justify-end gap-1">
                      {domainPrice.discountedPrice ? (
                        <>
                          <span className="text-xl font-bold text-jacarta-800 dark:text-white/70 line-through">
                            ${domainPrice.price}
                          </span>
                          <span className="text-3xl font-bold text-jacarta-700 dark:text-white">
                            ${domainPrice.discountedPrice}
                          </span>
                        </>
                      ) : (
                        <span className="text-3xl font-bold text-jacarta-700 dark:text-white">
                          ${domainPrice.price}
                        </span>
                      )}
                    </div>
          </div>

          {/* Reserved Tag */}
          <div className="flex items-center justify-between w-full mb-6">
            <div className="flex items-center gap-2">
              <div className="flex items-center gap-2 px-3 py-1.5 bg-green dark:bg-green/20 rounded-full">
                <FaCheckCircle className="text-green-500 dark:text-green-400" />
                <span className="text-green-500 dark:text-green-400 font-medium">Reserved</span>
              </div>
              <button className="text-sm hover:text-accent/80 ">What's this?</button>
            </div>
            <button
              onClick={ isInCart ? () => navigate : handleAddToCart}
           
              className={`px-4 py-2 bg-gradient-to-tr from-hotPink via-purplish to-accent text-white rounded-md hover:opacity-90 transition-all duration-200 font-bold`}
            >
              {isInCart ? 'Go to Checkout' : 'Add to Cart'}
            </button>
          </div>

          {/* Divider */}
          <div className="w-full border-t border-jacarta-600 my-4"></div>

          {/* Options Grid */}
          <div className="grid grid-cols-1 md:grid-cols-2 gap-6 w-full">
            {/* Free Option */}
            <div className="flex flex-col  rounded-md relative  gradient-box hover:z-10  w-full">
              <div className="relative dark:bg-jacarta-800 bg-white p-6 rounded-md  border border-jacarta-100 dark:border-jacarta-600 shadow-lg ">
                <div className="flex items-center justify-between mb-8">
                  <div>
                    <h3 className="text-2xl font-bold text-jacarta-700 dark:text-white mb-2">Don't Buy</h3>
                    <p className="text-jacarta-500 dark:text-white/70 text-sm">Start earning without investment</p>
                  </div>
                  <div className="text-right">
                    <div className="flex items-end justify-end gap-1">
                     
                          <span className="text-2xl font-bold text-jacarta-800 dark:text-white ">
                            $0
                          </span>
               
                    </div>
             
                  </div>
                </div>

                <div className="space-y-4 mb-8">
                  <div className="flex items-start gap-3 group">
                    <div className="mt-1 bg-accent/10 p-1.5 rounded-full">
                      <FaPercent className="text-accent text-lg" />
                    </div>
                    <div>
                      <p className="text-jacarta-700 dark:text-white font-medium">Earn 10% Revenue</p>
                      <p className="text-jacarta-500 dark:text-white/60 text-sm">From all SLD sales</p>
                    </div>
                  </div>

                  <div className="flex items-start gap-3 group">
                    <div className="mt-1 bg-accent/10 p-1.5 rounded-full">
                      <HiUsers className="text-accent text-lg" />
                    </div>
                    <div>
                      <p className="text-jacarta-700 dark:text-white font-medium">Keep Your Audience</p>
                      <p className="text-jacarta-500 dark:text-white/60 text-sm">Manage up to 100 wallets</p>
                    </div>
                  </div>

                  <div className="flex items-start gap-3 group">
                    <div className="mt-1 bg-accent/10 p-1.5 rounded-full">
                      <BsLightningChargeFill className="text-accent text-lg" />
                    </div>
                    <div>
                      <p className="text-jacarta-700 dark:text-white font-medium">10 Free Mints</p>
                      <p className="text-jacarta-500 dark:text-white/60 text-sm">For your community</p>
                    </div>
                  </div>

                  <div className="flex items-start gap-3 group">
                    <div className="mt-1 bg-accent/10 p-1.5 rounded-full">
                      <FaCheckCircle className="text-accent text-lg" />
                    </div>
                    <div>
                      <p className="text-jacarta-700 dark:text-white font-medium">100% NFT Revenue</p>
                      <p className="text-jacarta-500 dark:text-white/60 text-sm">Keep all NFT sale proceeds</p>
                    </div>
                  </div>

                  <div className="flex items-start gap-3 group">
                    <div className="mt-1 bg-accent/10 p-1.5 rounded-full">
                      <IoTimeOutline className="text-accent text-lg" />
                    </div>
                    <div>
                      <p className="text-jacarta-700 dark:text-white font-medium">48hr Purchase Window</p>
                      <p className="text-jacarta-500 dark:text-white/60 text-sm">If another buyer makes an offer</p>
                    </div>
                  </div>
                </div>

                <button onClick={()=>setIsOpen(false)} className="mt-auto w-full px-6 py-4 rounded-lg bg-transparent border-2 border-accent text-accent hover:bg-accent/10 transition-all duration-200 font-bold">
                  Start Free
                </button>
              </div>
            </div>
            {/* Buy Option */}
            <div className="flex flex-col  rounded-md relative  gradient-box hover:z-10  w-full">
              <div className="relative dark:bg-jacarta-800 bg-white p-6 rounded-md  border border-jacarta-100 dark:border-jacarta-600 shadow-lg ">
                <div className="flex items-center justify-between mb-8">
                  <div>
                    <h3 className="text-2xl font-bold text-jacarta-700 dark:text-white mb-2">Buy TLD</h3>
                    <p className="text-jacarta-500 dark:text-white/70 text-sm">Full ownership & benefits</p>
                  </div>
                  <div className="text-right">
                    <div className="flex items-end justify-end gap-1">
                      {domainPrice.discountedPrice ? (
                        <>
                          <span className="text-xl font-bold text-jacarta-800 dark:text-white/70 line-through">
                            ${domainPrice.price}
                          </span>
                          <span className="text-3xl font-bold text-jacarta-700 dark:text-white">
                            ${domainPrice.discountedPrice}
                          </span>
                        </>
                      ) : (
                        <span className="text-3xl font-bold text-jacarta-700 dark:text-white">
                          ${domainPrice.price}
                        </span>
                      )}
                    </div>
                    <div className="flex flex-col items-end">
                      {domainPrice.discountPercentage && (
                        <span className="text-green text-sm">
                          Save {domainPrice.discountPercentage}%
                        </span>
                      )}
                      <p className="text-jacarta-800 dark:text-white/70 text-sm">
                        no upfront cost
                      </p>
                    </div>
                  </div>
                </div>

                {/* Features list - Light mode aware */}
                <div className="space-y-5 mb-8">
                  <div className="flex items-start gap-3 group">
                    <div className="mt-1 bg-accent/20 p-1.5 rounded-full group-hover:bg-accent/30 transition-colors">
                      <HiUsers className="text-accent text-lg" />
                    </div>
                    <div>
                      <p className="text-jacarta-700 dark:text-white font-medium">Unlimited Wallets</p>
                      <p className=" dark:text-white/60 text-sm">No restrictions on connections</p>
                    </div>
                  </div>

                  <div className="flex items-start gap-3 group">
                    <div className="mt-1 bg-accent/20 p-1.5 rounded-full group-hover:bg-accent/30 transition-colors">
                      <FaPercent className="text-accent text-lg" />
                    </div>
                    <div>
                      <p className="text-jacarta-700 dark:text-white font-medium">50% Revenue Share</p>
                      <p className=" dark:text-white/60 text-sm">Higher earnings from SLD sales</p>
                    </div>
                  </div>

                  <div className="flex items-start gap-3 group">
                    <div className="mt-1 bg-accent/20 p-1.5 rounded-full group-hover:bg-accent/30 transition-colors">
                      <BsLightningChargeFill className="text-accent text-lg" />
                    </div>
                    <div>
                      <p className="text-jacarta-700 dark:text-white font-medium">Unlimited Mints</p>
                      <p className=" dark:text-white/60 text-sm">No minting restrictions</p>
                    </div>
                  </div>

                  <div className="flex items-start gap-3 group">
                    <div className="mt-1 bg-accent/20 p-1.5 rounded-full group-hover:bg-accent/30 transition-colors">
                      <FaCheckCircle className="text-accent text-lg" />
                    </div>
                    <div>
                      <p className="text-jacarta-700 dark:text-white font-medium">Advanced Analytics</p>
                      <p className=" dark:text-white/60 text-sm">Detailed insights & reporting</p>
                    </div>
                  </div>

                  <div className="flex items-start gap-3 group">
                    <div className="mt-1 bg-accent/20 p-1.5 rounded-full group-hover:bg-accent/30 transition-colors">
                      <FaCheckCircle className="text-accent text-lg" />
                    </div>
                    <div>
                      <p className="text-jacarta-700 dark:text-white font-medium">Priority Support</p>
                      <p className=" dark:text-white/60 text-sm">24/7 dedicated assistance</p>
                    </div>
                  </div>
                </div>

                {/* CTA Button - Enhanced for light mode */}
                <button 
                  onClick={handleAddToCart}
                  disabled={isInCart}
                  className={`relative w-full group ${
                    isInCart 
                      ? 'bg-gray-400 cursor-not-allowed' 
                      : 'bg-gradient-to-tr from-hotPink via-purplish to-accent hover:opacity-90'
                  } text-white py-3.5 rounded-md`}
                >
                  <span className="flex items-center justify-center gap-2 font-bold">
                    {isInCart ? 'Already in Cart' : 'Buy Now'}
                    {!isInCart && (
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        strokeWidth={2}
                        stroke="currentColor"
                        className="w-5 h-5 group-hover:translate-x-1 transition-transform"
                      >
                        <path strokeLinecap="round" strokeLinejoin="round" d="M13.5 4.5 21 12m0 0-7.5 7.5M21 12H3" />
                      </svg>
                    )}
                  </span>
                </button>
              </div>
            </div>
          </div>
        </div>
      );
    }
  };

  return (
    <Dialog open={isOpen} onClose={() => setIsOpen(false)} className="relative z-50">
      <div className="fixed inset-0 bg-jacarta-900/90 backdrop-blur-md" />

      <div className="fixed inset-0 overflow-y-auto">
        <div className="flex min-h-full items-center justify-center p-4">
          <Dialog.Panel className="w-full max-w-4xl transform overflow-hidden rounded-2xl bg-white dark:bg-jacarta-700 p-8 text-left align-middle shadow-xl transition-all">
            {/* Close button */}
            <button
              onClick={() => {
                setIsOpen(false);
                setCurrentStep(0);
                setSearchTerm("");
              }}
              className="absolute right-6 top-6  dark:text-jacarta-100 hover:text-accent dark:hover:text-accent"
            >
              <FaXmark className="h-6 w-6" />
            </button>

            <div className="mt-2">
              {renderStepContent()}

              {/* Step indicators */}
              <div className="flex justify-center gap-3 mt-8">
                <div
                  className={`h-2 rounded-full transition-all ${currentStep === 0 ? "w-8 bg-accent" : "w-2 bg-jacarta-200 dark:bg-jacarta-500"}`}
                />
                <div
                  className={`h-2 rounded-full transition-all ${currentStep === 1 ? "w-8 bg-accent" : "w-2 bg-jacarta-200 dark:bg-jacarta-500"}`}
                />
                <div
                  className={`h-2 rounded-full transition-all ${currentStep === 2 ? "w-8 bg-accent" : "w-2 bg-jacarta-200 dark:bg-jacarta-500"}`}
                />
                <div
                  className={`h-2 rounded-full transition-all ${currentStep === 3 ? "w-8 bg-accent" : "w-2 bg-jacarta-200 dark:bg-jacarta-500"}`}
                />
              </div>
            </div>
          </Dialog.Panel>
        </div>
      </div>
    </Dialog>
  );
}
