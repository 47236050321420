import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  selectedTld: null,
};

export const flowsSlice = createSlice({
  name: 'flows',
  initialState,
  reducers: {
    setSelectedTld: (state, action) => {
      state.selectedTld = action.payload;
    },
  },
});

export const { setSelectedTld } = flowsSlice.actions;

export default flowsSlice.reducer; 