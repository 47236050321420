import { useDisconnect, useActiveWallet } from "thirdweb/react";
import { useRouter } from "next/router";

export const useWalletDisconnect = () => {
  const { disconnect } = useDisconnect();
  const wallet = useActiveWallet();
  const navigate = useRouter();

  const handleDisconnect = async () => {
    try {
      console.log("handleDisconnect")
      // Disconnect the wallet
      await fetch('/api/auth/logout', { method: 'GET' });
 
      if (wallet) {
        disconnect(wallet);
      }

      // Clear the session

 window.location.href = '/signin';
      // Clear all auth-related cookies
      // const cookies = document.cookie.split(';');
      // cookies.forEach(cookie => {
      //   const name = cookie.split('=')[0].trim();
      //   if (name.includes('thirdweb') ||
      //     name.includes('auth') ||
      //     name === 'walletAddress') {
      //     Cookies.remove(name, { path: '/' });
      //     // Cookies.remove('isConnected', { path: '/' });
      //   }
      // });
      return true;
    } catch (error) {
      console.error('Disconnect error:', error);
      return false;
    }
  };

  return {
    handleDisconnect
  };
}; 