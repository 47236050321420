import { useTheme } from "next-themes";
import { MdOutlineWbSunny } from "react-icons/md";
import { FaRegMoon } from "react-icons/fa";
import { useEffect, useState } from "react";

export default function DarkModeSwitcher() {
  const { theme, setTheme } = useTheme();
  const [isDark, setIsDark] = useState(true);
  const [mounted, setMounted] = useState(false);

  // Handle initial client-side render
  useEffect(() => {
    setMounted(true);
    setIsDark(theme === "dark");
  }, [theme]);

 

  const toggleTheme = (newTheme) => {
    setTheme(newTheme);
    setIsDark(newTheme === "dark");
  };

   // Avoid hydration mismatch
   if (!mounted) return null;

  return (
    <div className="flex items-center justify-center my-auto w-20">
      <div className="relative flex w-full px-2 py-1 rounded-md">
        <span
          className="absolute inset-0 m-1 pointer-events-none dark:bg-jacarta-800 bg-white shadow-md rounded-md"
          aria-hidden="true"
        >
          <span
            className={`absolute inset-0 w-1/2 bg-jacarta-900 text-white dark:bg-jacarta-600 dark:border  dark:border-gray-200 rounded-md shadow-sm shadow-indigo-950/10 transform transition-transform duration-150 ease-in-out ${
              !isDark ? "translate-x-0" : "translate-x-full"
            }`}
          ></span>
        </span>
        <button
          className={`relative flex-1 text-md font-medium h-8 rounded-md flex items-center justify-center focus-visible:outline-none focus-visible:ring  dark:focus-visible:ring-slate-600 transition-colors duration-150 ease-in-out ${
            !isDark
              ? "text-white font-semibold"
              : "dark:text-jacarta-400 text-jacarta-600"
          }`}
          onClick={() => toggleTheme("light")}
          aria-label="Switch to light mode"
        >
          <MdOutlineWbSunny className="h-4 w-4 mr-1 text-white" />
        </button>
        <button
          className={`relative flex-1 text-md font-medium h-8 rounded-md flex items-center justify-center focus-visible:outline-none focus-visible:ring  dark:focus-visible:ring-slate-600 transition-colors duration-150 ease-in-out ${
            isDark
              ? "text-white font-semibold"
              : "dark:text-jacarta-400 text-jacarta-600"
          }`}
          onClick={() => toggleTheme("dark")}
          aria-label="Switch to dark mode"
        >
          <FaRegMoon className="h-4 w-4 ml-1" />
        </button>
      </div>
    </div>
  );
}