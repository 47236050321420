import Image from 'next/image'
import Link from 'next/link'
import React from 'react'

function ZWalletLogo({ className="w-36"}) {
  return (
    <Link
     className="shrink-0 block" href="/">
          <div className="dark:hidden">
            <Image
              src={"/domainz-white.svg"}
              height={28}
              width={90}
              alt="z-wallet"
              className={className}
            />
          </div>
          <div className="hidden dark:block">
            <Image
              src={"/domainz-dark.svg"}
              height={28}
              width={90}
              alt="z-wallet"
              className={className}
            />
          </div>
        </Link>
  )
}

export default ZWalletLogo