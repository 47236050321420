import "../styles/CustomDateRange.css";
import "../styles/globals.css";

import { ThemeProvider, useTheme } from "next-themes";
import PublicLayout from "../components/layout";
import { Provider } from "react-redux";
import { store } from "../redux/store";
import { MetaMaskProvider } from "metamask-react";
import UserContext from "../components/UserContext";
import React, { useEffect, useRef, useState } from "react";
import config from "react-reveal/globals";

import client from "../AppoloClient";
import { ApolloProvider } from "@apollo/client";
import "react-modern-drawer/dist/index.css";
import "swiper/css";
import "swiper/css/navigation";
import "tippy.js/dist/tippy.css";

import { QueryClient, QueryClientProvider } from "react-query";
import { Hydrate } from "react-query/hydration";
import ThirdWebCustomProvider from "../ThirdWebCustomProvider";
import { PagesProgressBar as ProgressBar } from "next-nprogress-bar";
import axios from "axios";

const queryClient = new QueryClient(
  {
    defaultOptions: {
      queries: {
        cacheTime: 0, // 1 minute
        staleTime: 0, // 30 seconds

        refetchOnWindowFocus: false,
        refetchOnReconnect: false,
      },
    },
  }
);
function MyApp({ Component, pageProps: { session, ...pageProps } }) {
  const [protectedSite, setProtectedSite] = useState(true);
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const scrollRef = useRef({ scrollPos: 0 });

  config({ ssrFadeout: true });


  // useEffect(() => {
  //   // Skip protection in development
  //   if (process.env.NODE_ENV === 'development') {
  //     setProtectedSite(false);
  //   }
  // }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    setError("");

    try {
      await axios.post('/api/verify-password', {
        password
      });

      setProtectedSite(false);
    } catch (err) {
      // Axios error handling
      const errorMessage = err.response?.data?.error || "An error occurred. Please try again.";
      setError(errorMessage);
    } finally {
      setIsLoading(false);
    }
  };

  // if (protectedSite && process.env.NODE_ENV === 'production') {
  //   return (
  //     <div className="fixed inset-0 z-[9999] flex items-center justify-center bg-jacarta-900/90 backdrop-blur-md">
  //       <div className="w-full max-w-md p-6 mx-4">
  //         <form
  //           onSubmit={handleSubmit}
  //           className="rounded-2xl border border-accent/20 bg-white dark:bg-jacarta-700 text-jacarta-700 dark:text-white shadow-xl w-full space-y-4"
  //         >
  //           <div className="flex flex-col space-y-1.5 p-6">
  //             <h3 className="text-2xl font-bold text-center">
  //               Password Protected
  //             </h3>
  //             <p className="text-sm text-center text-jacarta-500 dark:text-jacarta-300">
  //               Please enter the password to access the site
  //             </p>
  //           </div>

  //           <div className="px-6 space-y-4">
  //             <div className="space-y-2">
  //               <label
  //                 className="text-sm font-medium leading-none"
  //                 htmlFor="password"
  //               >
  //                 Password
  //               </label>
  //               <input
  //                 onChange={(e) => setPassword(e.target.value)}
  //                 className="flex h-10 w-full rounded-md border border-accent/20 bg-white dark:bg-jacarta-700 px-3 py-2 text-sm ring-offset-background file:border-0 file:bg-transparent file:text-sm file:font-medium placeholder:text-jacarta-500 focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-accent focus-visible:ring-offset-2"
  //                 id="password"
  //                 placeholder="Enter your password"
  //                 type="password"
  //                 autoFocus
  //                 disabled={isLoading}
  //               />
  //             </div>
  //             {error && (
  //               <p className="text-red-500 text-sm text-center">{error}</p>
  //             )}
  //           </div>

  //           <div className="flex items-center p-6">
  //             <button
  //               className="inline-flex w-full items-center justify-center rounded-md bg-accent px-4 py-2 text-sm font-medium text-white hover:bg-accent/80 focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-accent focus-visible:ring-offset-2 disabled:pointer-events-none disabled:opacity-50 transition-colors"
  //               type="submit"
  //               disabled={isLoading}
  //             >
  //               {isLoading ? "Verifying..." : "Enter"}
  //             </button>
  //           </div>
  //         </form>
  //       </div>
  //     </div>
  //   );
  // }

  return (
    <QueryClientProvider client={queryClient}>
      <Hydrate state={pageProps.dehydratedState}>
        <Provider store={store}>
          <ThemeProvider attribute="class"
            defaultTheme="dark" // Set an explicit default theme
            enableSystem={true}  // Enable system theme detection
            value={{
              light: 'light',
              dark: "dark",
            }}
          >
            <ThirdWebCustomProvider>
              <PublicLayout>
                <MetaMaskProvider>
                  <UserContext.Provider value={{ scrollRef: scrollRef }}>
                    <ApolloProvider client={client}>
                      {/* <AutoConnectProvider> */}
                      <Component {...pageProps} />
                      <ProgressBar
                        height="3px"
                        color="#02f6d1"
                        options={{ showSpinner: false }}
                        shallowRouting
                      />
                      {/* </AutoConnectProvider> */}
                    </ApolloProvider>
                  </UserContext.Provider>
                </MetaMaskProvider>
              </PublicLayout>
            </ThirdWebCustomProvider>
          </ThemeProvider>
        </Provider>
      </Hydrate>
    </QueryClientProvider>
  );
}

export default MyApp;
