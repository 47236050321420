'use client';
import Cookies from 'js-cookie';

const useJwtAuth = () => {
  
  const isConnected = Cookies.get('isConnected');
  if (isConnected) {
    return true;
  }

  return null;
};

export default useJwtAuth;
