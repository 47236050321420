// function calculateDomainPrice(domainName) {
// 	// Base Prices for Each Length
// 	const basePrices = {
// 		2: [1000000, 1000000], // Fixed price for 2 characters
// 		3: [8000, 100000],
// 		4: [2000, 100000],
// 		5: [1000, 100000],
// 		6: [400, 25000],
// 		7: [300, 15000],
// 		8: [250, 10000],
// 		9: [200, 5000],
// 		10: [150, 5000],
// 		11: [100, 5000],
// 		12: [90, 5000],
// 		13: [80, 5000],
// 		14: [70, 5000],
// 		15: [69, 5000],
// 		16: [69, 5000] // Same for 16 and above
// 	};

// 	// Premium Words and Multipliers
// 	const premiumWords = {
// 		"Tom": 3.2,
// 		"Ben": 3,
// 		"Richard": 7,
// 		"Chain": 5,
// 		"Crypto": 1000, // Fixed additional price
// 		"Ai": 3,
// 		"Blockchain": 2,
// 		"Block": 1.2,
// 		// "Chain" already included above
// 	};

// 	// Determine the length of the domain (TLD only)
// 	let length = domainName.length;
// 	let basePrice = length in basePrices ? basePrices[length] : [69, 5000]; // Default for >16 characters

// 	// Adjust base price for premium words
// 	Object.keys(premiumWords).forEach(word => {
// 		if (domainName.toLowerCase().includes(word)) {
// 			if (typeof premiumWords[word] === "number") {
// 				basePrice = [basePrice[0] + premiumWords[word], basePrice[1] + premiumWords[word]];
// 			} else {
// 				basePrice = basePrice.map(price => price * premiumWords[word]);
// 			}
// 		}
// 	});

// 	// Calculate and return the final price
// 	return Math.min(basePrice[1], Math.min(basePrice[0], basePrice[1]));
// }

function isPremium(word) {
	// This function should actually query your database to check if the word is premium
	const premiumWords = ["example", "premium", "gold"]; // Example premium words
	return premiumWords.includes(word.toLowerCase());
}

// Function to calculate SLD price based on length
function calculateSLDPrice(sld) {
	const length = sld.length;
	let originalPrice = 0;

	if (length === 1) originalPrice = 10000;
	else if (length === 2) originalPrice = 100;
	else if (length === 3) originalPrice = 30;
	else originalPrice = 10; // For 4 or more characters

	return originalPrice;
}

// Function to calculate TLD price based on length
function calculateTLDPrice(tld) {
	const length = tld.length;
	let originalPrice = { min: 0, max: 0 };

	if (length === 2) originalPrice = { min: 100000, max: 100000 };
	if (length === 3) originalPrice = { min: 800, max: 10000 };
	if (length === 4) originalPrice = { min: 200, max: 10000 };
	if (length === 5) originalPrice = { min: 100, max: 10000 };
	if (length === 6) originalPrice = { min: 40, max: 2500 };
	if (length === 7) originalPrice = { min: 30, max: 1500 };
	if (length === 8) originalPrice = { min: 25, max: 1000 };
	if (length === 9) originalPrice = { min: 20, max: 500 };
	if (length === 10) originalPrice = { min: 15, max: 500 };
	if (length === 11) originalPrice = { min: 10, max: 500 };
	if (length === 12) originalPrice = { min: 9, max: 500 };
	if (length === 13) originalPrice = { min: 8, max: 500 };
	if (length === 14) originalPrice = { min: 7, max: 500 };
	if (length === 15) originalPrice = { min: 6, max: 500 };
	if (length >= 16) originalPrice = { min: 6, max: 500 };

	return originalPrice;
}

// Function to get total price for a domain
function calculateDomainPrice(sld, tld) {
	const sldOriginalPrice = sld ? calculateSLDPrice(sld) : 0;
	const tldOriginalPrice = tld ? calculateTLDPrice(tld) : { min: 0, max: 0 };

	const originalTotalPrice = sldOriginalPrice + tldOriginalPrice.max;
	const discountedTotalPrice = originalTotalPrice

	// * 0.1;

	console.log(`SLD Original Price: $${sldOriginalPrice}`);
	console.log(`TLD Original Price Range: $${tldOriginalPrice.min} - $${tldOriginalPrice.max}`);
	console.log(`Original Total Price: $${originalTotalPrice}`);
	console.log(`Discounted Total Price: $${discountedTotalPrice}`);

	return {

		// discountedPrice: discountedTotalPrice,
		// discountPercentage: 90,
		total: originalTotalPrice

	};
}




export default calculateDomainPrice


