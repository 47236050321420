import Head from "next/head";

const Meta = ({ title, keyword, desc, ogImage, ogTitle, ogUrl }) => {
  const finalOgTitle = ogTitle ?? title ?? "NetZylo | AI-Powered Premium Web3 Domain Provider";
  const finalOgImage = ogImage ?? "/images/og-image.png";
  const finalDesc = desc ?? "Discover the future of domain registration with our AI-powered premium Web3 domain services. Explore blockchain, crypto, and NFT domains with cutting-edge technology and secure your place in the decentralized web. Invest in unique domains, backed by smart contracts, and be part of the next-generation Web3 ecosystem. Trust our expertise in blockchain innovation, digital assets, and future-proof domain solutions.";
  const finalUrl = ogUrl ?? "https://netzylo.com";

  return (
    <div>
      <Head>
        <title>{title ?? "NetZylo | AI-Powered Premium Web3 Domain Provider"}</title>
        <link rel="icon" href="/favicon.png" />
        <meta name="description" content={finalDesc} />
        <meta name="keyword" content={keyword} />
        <meta property="og:type" content="website" />
        <meta property="og:url" content={finalUrl} />
        <meta property="og:title" content={finalOgTitle} />
        <meta property="og:description" content={finalDesc} />
        <meta property="og:image" content={finalOgImage} />
        <meta property="twitter:card" content="summary_large_image" />
        <meta property="twitter:url" content={finalUrl} />
        <meta property="twitter:title" content={finalOgTitle} />
        <meta property="twitter:description" content={finalDesc} />
        <meta property="twitter:image" content={finalOgImage} />
        <link rel="apple-touch-icon" sizes="180x180" href="/apple-touch-icon.png" />
        <link rel="icon" type="image/png" sizes="32x32" href="/favicon-32x32.png" />
        <link rel="icon" type="image/png" sizes="16x16" href="/favicon-16x16.png" />
        <link rel="manifest" href="/site.webmanifest" />
        <link rel="mask-icon" href="/safari-pinned-tab.svg" color="#00aba9" />
        <meta name="msapplication-TileColor" content="#00aba9" />
        <meta name="theme-color" content="#00aba9"></meta>
        <meta property="og:locale" content="en_US"></meta>
      </Head>
    </div>
  );
};

Meta.defaultProps = {
  title: "NetZylo | AI-Powered Premium Web3 Domain Provider",
  keyword:
    "AI-Powered Premium Web3 Domain Provider, AI-Powered, Premium, Web3, Domain Provider, Blockchain Domains, Crypto Domains, NFT Domains, Decentralized, Smart Contracts, Cutting-Edge, Blockchain Innovation, Digital Assets, Web3 Ecosystem, Next-Generation, Tech-Driven, Unique Domains, Domain Investment, Future-Proof Domains, AI-Powered Insights, Blockchain Security",
  desc: "Discover the future of domain registration with our AI-powered premium Web3 domain services. Explore blockchain, crypto, and NFT domains with cutting-edge technology and secure your place in the decentralized web. Invest in unique domains, backed by smart contracts, and be part of the next-generation Web3 ecosystem. Trust our expertise in blockchain innovation, digital assets, and future-proof domain solutions.",
  ogImage: "/images/og-image.png",
  ogTitle: "NetZylo | AI-Powered Premium Web3 Domain Provider",
  ogUrl: "https://netzylo.com"
};

export default Meta;
