import { Web3Resolver } from "web3-domain-resolver";
import { createThirdwebClient } from "thirdweb";
import { polygon } from "thirdweb/chains";
import { getContract } from "thirdweb";
import { readContract } from "thirdweb";
import { ethers } from "ethers";
import web3Config from "../web3Config";
import { ConnectionLibrary } from "web3-domain-resolver";
import { NetworkName } from "web3-domain-resolver";

async function checkDomainAvailability(fullname) {
  try {
    fullname = fullname.toLowerCase();
    console.log("checkDomainAvailability:" + fullname);

    // Try creating Thirdweb client
    let client;
    try {
      client = createThirdwebClient({
        clientId: process.env.NEXT_PUBLIC_THIRDWEB_CLIENT_ID,
      });
    } catch (clientError) {
      console.error("Error creating Thirdweb client:", clientError.message);
      // Fallback to basic provider if client creation fails
      client = null;
    }

    // Try getting contract through multiple methods
    let contract;
    let isAvailableInContract = false;

    // Method 1: Thirdweb
    try {
      if (client) {
        contract = getContract({
          client,
          chain: polygon,
          address: web3Config.contractAddress,
          abi: web3Config.abi,
        });

        isAvailableInContract = await readContract({
          contract: contract,
          method: "function isDomainAvailable(string memory domainName) public view returns (bool)",
          params: [fullname],
        });
      }
    } catch (thirdwebError) {
      console.error("Thirdweb contract interaction failed:", thirdwebError.message);

      // Method 2: ethers.js with default RPC
      try {
        console.log("checking default rpc");
        const provider = new ethers.providers.JsonRpcProvider(getDecryptedRPC());
        const ethersContract = new ethers.Contract(web3Config.contractAddress, web3Config.abi, provider);
        isAvailableInContract = await ethersContract.isDomainAvailable(fullname);
      } catch (ethersError) {
        console.error("Default RPC ethers.js attempt failed:", ethersError.message);

        // Method 3: Try alternate RPC endpoints
        const alternateRPCs = [
          "https://polygon.llamarpc.com",
          "https://polygon-rpc.com",
          "https://rpc-mainnet.matic.network",
          "https://matic-mainnet.chainstacklabs.com",
        ];

        for (const rpcUrl of alternateRPCs) {
          try {
            console.log("trying alternate rpc" + rpcUrl);

            const altProvider = new ethers.providers.JsonRpcProvider(rpcUrl);
            const altContract = new ethers.Contract(web3Config.contractAddress, web3Config.abi, altProvider);
            isAvailableInContract = await altContract.isDomainAvailable(fullname);
            break; // Exit loop if successful
          } catch (altError) {
            console.error(`Alternative RPC ${rpcUrl} failed:`, altError.message);
            continue;
          }
        }
      }
    }

    // Web3Resolver section with error handling
    let resolvedDomain;
    try {
      const networkConnection = {
        networkName: NetworkName.AURORA,
        rpcUrl: "https://mainnet.aurora.dev",
      };
      const connectionLibrary = new ConnectionLibrary([networkConnection]);
      const web3resolver = new Web3Resolver(connectionLibrary);
      resolvedDomain = await web3resolver.resolve(fullname);
    } catch (resolverError) {
      console.error("Web3Resolver error:", resolverError.message);
      // Fallback: If resolver fails, assume domain might be taken to be safe
      resolvedDomain = "RESOLUTION_FAILED";
    }

    // Final availability check with all gathered information
    if (!isAvailableInContract) {
      console.log("Domain Booked in Smart Contract: " + fullname);
      return false;
    }

    if (resolvedDomain === undefined || resolvedDomain === null) {
      console.log("Available " + fullname + "  " + true);
      return true;
    }

    return false;
  } catch (globalError) {
    console.error("Global error in checkDomainAvailability:", globalError.message);
    throw new Error("Domain availability check failed completely");
  }
}

export default checkDomainAvailability;
